.main_div_PrivacyResponsibility {
  background: #027f00;
  width: 100%;
  height: auto;
  padding-top: 30px;
  padding-bottom: 30px;
}
.heading_privacyresp {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 39.01px;
  text-align: center;
}
.para_privacyresp {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 23.16px;
  text-align: center;
  width: 60%;
  margin-left: 20%;
}
@media only screen and (max-width: 1640px) {
  .main_div_PrivacyResponsibility {
    background: #027f00;
    width: 100%;
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .heading_privacyresp {
    color: #ffffff;
    font-family: "Montserrat", sans-serif;
    font-size: 26px;
    font-weight: 700;
    line-height: 36.01px;
    text-align: center;
  }
  .para_privacyresp {
    color: #ffffff;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.16px;
    text-align: center;
    width: 60%;
    margin-left: 20%;
  }
}

@media only screen and (max-width: 1200px) {
  .main_div_PrivacyResponsibility {
    background: #027f00;
    width: 100%;
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .heading_privacyresp {
    color: #ffffff;
    font-family: "Montserrat", sans-serif;
    font-size: 22px;
    font-weight: 700;
    line-height: 32.01px;
    text-align: center;
  }
  .para_privacyresp {
    color: #ffffff;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18.16px;
    text-align: center;
    width: 60%;
    margin-left: 20%;
  }
}

@media only screen and (max-width: 991px) {
  .main_div_PrivacyResponsibility {
    background: #027f00;
    width: 100%;
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .heading_privacyresp {
    color: #ffffff;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 28.01px;
    text-align: center;
  }
  .para_privacyresp {
    color: #ffffff;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.16px;
    text-align: center;
    width: 60%;
    margin-left: 20%;
  }
}

@media only screen and (max-width: 767px) {
  .main_div_PrivacyResponsibility {
    background: #027f00;
    width: 100%;
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .heading_privacyresp {
    color: #ffffff;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24.01px;
    text-align: center;
  }
  .para_privacyresp {
    color: #ffffff;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 15.16px;
    text-align: center;
    width: 60%;
    margin-left: 20%;
  }
}

@media only screen and (max-width: 600px) {
  .main_div_PrivacyResponsibility {
    background: #027f00;
    width: 100%;
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .heading_privacyresp {
    color: #ffffff;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 20.01px;
    text-align: center;
  }
  .para_privacyresp {
    color: #ffffff;
    font-family: "Montserrat", sans-serif;
    font-size: 11px;
    font-weight: 400;
    line-height: 14.16px;
    text-align: center;
    width: 80%;
    margin-left: 10%;
  }
}
