.main_div_AboutUsNavbar {
  width: 100%;
  height: 160px;
  background: #027f00;
}
.heading_about{
    color: #FFFFFF;
    font-family: "Montserrat", sans-serif;
    font-size: 48px;
    font-weight: 900;
    line-height: 58.51px;
    text-align: center;
padding-top: 45px;
}
@media only screen and (max-width: 1200px) {
    .heading_about{
        color: #FFFFFF;
        font-family: "Montserrat", sans-serif;
        font-size: 40px;
        font-weight: 900;
        line-height: 58.51px;
        text-align: center;
    padding-top: 45px;
    }
}
@media only screen and (max-width: 767px) {
    .heading_about{
        color: #FFFFFF;
        font-family: "Montserrat", sans-serif;
        font-size: 36px;
        font-weight: 900;
        line-height: 58.51px;
        text-align: center;
    padding-top: 45px;
    }
}
@media only screen and (max-width: 600px) {
    .heading_about{
        color: #FFFFFF;
        font-family: "Montserrat", sans-serif;
        font-size: 26px;
        font-weight: 900;
        line-height: 58.51px;
        text-align: center;
    padding-top: 45px;
    }
}