/* To center the spinner*/
.pos-center {
    position: fixed;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
  }
  
  .loader {
    border: 5px solid #f3f3f3;
    border-top: 7px solid #525252;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    margin-top: 1%;
    margin-bottom: 1%;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  