.main_div_footer {
  width: 100%;
  height: 384px;
  background: #fda726;
}
.heading_overview {
  color: #222222;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  text-align: left;
  margin-top: 100px;
  text-decoration: underline;
}
.para_overview {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  color: #222222;
}
.icon_social_links {
  margin-right: 15px;
  margin-top: 30px;
}
.input_style_email_footer {
  border: 1px solid #222222;
  width: 100%;
  height: 45px;
  color: #222222;
  font-family: "Montserrat", sans-serif;
  font-size: 15.5px;
  font-weight: 400;
  line-height: 18.89px;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  outline: none;
  background: #fda726;
}
.para_subscribe {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #222222;
  margin-top: 10px;
  cursor: pointer;
}
.arrow_subscribe {
  margin-top: 15px;
  width: 19px;
  height: 11px;
  margin-left: 10px;
  cursor: pointer;
}
@media only screen and (max-width: 1320px) {
    .main_div_footer {
        width: 100%;
        height: 384px;
        background: #fda726;
      }
      .heading_overview {
        color: #222222;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 23px;
        text-align: left;
        margin-top: 100px;
        text-decoration: underline;
      }
      .para_overview {
        font-family: "Rubik", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
        text-align: left;
        color: #222222;
      }
      .icon_social_links {
        margin-right: 15px;
        margin-top: 30px;
      }
      .input_style_email_footer {
        border: 1px solid #222222;
        width: 100%;
        height: 45px;
        color: #222222;
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 18.89px;
        text-align: left;
        padding-left: 10px;
        padding-right: 10px;
        outline: none;
        background: #fda726;
      }
      .para_subscribe {
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: #222222;
        margin-top: 10px;
      }
      .arrow_subscribe {
        margin-top: 15px;
        width: 19px;
        height: 11px;
        margin-left: 10px;
      }
      
}

@media only screen and (max-width: 1200px) {
    .main_div_footer {
        width: 100%;
        height: 384px;
        background: #fda726;
      }
      .heading_overview {
        color: #222222;
        font-family: "Roboto", sans-serif;
        font-size: 12px;
        font-weight: 700;
        line-height: 23px;
        text-align: left;
        margin-top: 100px;
        text-decoration: underline;
      }
      .para_overview {
        font-family: "Rubik", sans-serif;
        font-size: 11px;
        font-weight: 400;
        line-height: 26px;
        text-align: left;
        color: #222222;
      }
      .icon_social_links {
        margin-right: 15px;
        margin-top: 30px;
      }
      .input_style_email_footer {
        border: 1px solid #222222;
        width: 100%;
        height: 45px;
        color: #222222;
        font-family: "Montserrat", sans-serif;
        font-size: 11px;
        font-weight: 400;
        line-height: 18.89px;
        text-align: left;
        padding-left: 10px;
        padding-right: 10px;
        outline: none;
        background: #fda726;
      }
      .para_subscribe {
        font-family: "Roboto", sans-serif;
        font-size: 11px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: #222222;
        margin-top: 10px;
      }
      .arrow_subscribe {
        margin-top: 15px;
        width: 19px;
        height: 11px;
        margin-left: 10px;
      }
      
}
@media only screen and (max-width: 991px) {
    .main_div_footer {
        width: 100%;
        height: auto;
        padding-top: 30px;
        padding-bottom: 30px;
        background: #fda726;
      }
      .heading_overview {
        color: #222222;
        font-family: "Roboto", sans-serif;
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        text-align: left;
        margin-top: 20px;
        text-decoration: underline;
      }
      .para_overview {
        font-family: "Rubik", sans-serif;
        font-size: 11px;
        font-weight: 400;
        line-height: 14px;
        text-align: left;
        color: #222222;
      }
      .icon_social_links {
        width: 27px;
        height: 25px;
        margin-right: 15px;
        margin-top: 15px;
      }
      .input_style_email_footer {
        border: 1px solid #222222;
        width: 100%;
        height: 35px;
        color: #222222;
        font-family: "Montserrat", sans-serif;
        font-size: 11px;
        font-weight: 400;
        line-height: 18.89px;
        text-align: left;
        padding-left: 10px;
        padding-right: 10px;
        outline: none;
        background: #fda726;
      }
      .para_subscribe {
        font-family: "Roboto", sans-serif;
        font-size: 11px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: #222222;
        margin-top: 10px;
      }
      .arrow_subscribe {
        margin-top: 15px;
        width: 19px;
        height: 11px;
        margin-left: 10px;
      }
      
}


@media only screen and (max-width: 525px) {
    .main_div_footer {
        width: 100%;
        height: auto;
        padding-top: 30px;
        padding-bottom: 30px;
        background: #fda726;
      }
      .heading_overview {
        color: #222222;
        font-family: "Roboto", sans-serif;
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        text-align: left;
        margin-top: 20px;
        text-decoration: underline;
      }
      .para_overview {
        font-family: "Rubik", sans-serif;
        font-size: 11px;
        font-weight: 400;
        line-height: 12px;
        text-align: left;
        color: #222222;
      }
      .icon_social_links {
        width: 27px;
        height: 25px;
        margin-right: 15px;
        margin-top: 15px;
      }
      .input_style_email_footer {
        border: 1px solid #222222;
        width: 100%;
        height: 35px;
        color: #222222;
        font-family: "Montserrat", sans-serif;
        font-size: 11px;
        font-weight: 400;
        line-height: 18.89px;
        text-align: left;
        padding-left: 10px;
        padding-right: 10px;
        outline: none;
        background: #fda726;
      }
      .para_subscribe {
        font-family: "Roboto", sans-serif;
        font-size: 11px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: #222222;
        margin-top: 10px;
      }
      .arrow_subscribe {
        margin-top: 15px;
        width: 19px;
        height: 11px;
        margin-left: 10px;
      }
      
}