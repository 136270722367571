.main_div_logo_transition {
  background: #f5f5f5;
  width: 100%;
  padding: 0;
  margin: 0;
  height: 140px;
  padding-top: 45px;
}

.slick-next:before,
.slick-prev:before {
  display: none;
}

.slick-next {
  display: none;
  /* right: 0px !important; */
}

.main_div_blue{
    background: #006BD4;
    width: 100%;
    height: 50px;

    
}