.main_div_GetinTouch {
  background: #f5f5f5;
  width: 100%;
  height: auto;
  padding-top: 50px;
  padding-bottom: 50px;
}
.main_div_form_getintouch {
  background: #ffffff;
  width: 100%;
  height: 531px;
  padding: 15px;
}
.heading_getintouch {
  color: #e21c1c;
  font-family: "Montserrat", sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
  margin-top: 15px;
  margin-left: 15px;
}
.input_style_getintouch {
  width: 100%;
  height: 50px;
  border: 1px solid #2323231f;
  color: #6d6d6d;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.96px;
  text-align: left;
  padding-left: 7px;
  padding-right: 7px;
  outline: none;
  margin-top: 10px;
  margin-bottom: 10px;
}
.textarea_style_getintouch {
  width: 100%;
  height: 135px;
  border: 1px solid #2323231f;
  color: #6d6d6d;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.96px;
  text-align: left;
  padding-left: 7px;
  padding-right: 7px;
  outline: none;
  margin-top: 10px;
  margin-bottom: 40px;
}
.btn_getintouch {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  width: 100%;
  border-style: none;
  border-color: none;
  height: 55px;
  background: #e00d0d;
}

.main_div_companydetails {
  background: #006bd4;
  width: 100%;
  height: 531px;
  padding: 30px;
}
.companydetailicons {
  width: 60px;
  height: 60px;
  margin-top: 20px;
}
.heading_company {
    margin-top: 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #ffffff;
  margin-left: 15px;
}
.para_company {
  margin-left: 15px;
  color: #ffffffbd;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  
}
.white_div_getintouch{
    width: 100%;
    height: 60px;
    background-color: white;
}


@media only screen and (max-width: 1800px) {
    .main_div_GetinTouch {
        background: #f5f5f5;
        width: 100%;
        height: auto;
        padding-top: 50px;
        padding-bottom: 50px;
      }
      .main_div_form_getintouch {
        background: #ffffff;
        width: 100%;
        height: 531px;
        padding: 15px;
      }
      .heading_getintouch {
        color: #e21c1c;
        font-family: "Montserrat", sans-serif;
        font-size: 32px;
        font-weight: 700;
        line-height: 36px;
        text-align: left;
        margin-top: 15px;
        margin-left: 15px;
      }
      .input_style_getintouch {
        width: 100%;
        height: 50px;
        border: 1px solid #2323231f;
        color: #6d6d6d;
        font-family: "Rubik", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 18.96px;
        text-align: left;
        padding-left: 7px;
        padding-right: 7px;
        outline: none;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .textarea_style_getintouch {
        width: 100%;
        height: 135px;
        border: 1px solid #2323231f;
        color: #6d6d6d;
        font-family: "Rubik", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 18.96px;
        text-align: left;
        padding-left: 7px;
        padding-right: 7px;
        outline: none;
        margin-top: 10px;
        margin-bottom: 40px;
      }
      .btn_getintouch {
        color: #ffffff;
        font-family: "Montserrat", sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        width: 100%;
        border-style: none;
        border-color: none;
        height: 55px;
        background: #e00d0d;
      }
      
      .main_div_companydetails {
        background: #006bd4;
        width: 100%;
        height: 531px;
        padding: 25px;
      }
      .companydetailicons {
        width: 60px;
        height: 60px;
        margin-top: 20px;
      }
      .heading_company {
          margin-top: 20px;
        font-family: "Montserrat", sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        color: #ffffff;
        margin-left: 15px;
      }
      .para_company {
        margin-left: 15px;
        color: #ffffffbd;
        font-family: "Rubik", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 12px;
        text-align: left;
        
      }
      
      

}




@media only screen and (max-width: 1250px) {
    .main_div_GetinTouch {
        background: #f5f5f5;
        width: 100%;
        height: auto;
        padding-top: 50px;
        padding-bottom: 50px;
      }
      .main_div_form_getintouch {
        background: #ffffff;
        width: 100%;
        height: 531px;
        padding: 15px;
      }
      .heading_getintouch {
        color: #e21c1c;
        font-family: "Montserrat", sans-serif;
        font-size: 26px;
        font-weight: 700;
        line-height: 36px;
        text-align: left;
        margin-top: 15px;
        margin-left: 15px;
      }
      .input_style_getintouch {
        width: 100%;
        height: 50px;
        border: 1px solid #2323231f;
        color: #6d6d6d;
        font-family: "Rubik", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 18.96px;
        text-align: left;
        padding-left: 7px;
        padding-right: 7px;
        outline: none;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .textarea_style_getintouch {
        width: 100%;
        height: 135px;
        border: 1px solid #2323231f;
        color: #6d6d6d;
        font-family: "Rubik", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 18.96px;
        text-align: left;
        padding-left: 7px;
        padding-right: 7px;
        outline: none;
        margin-top: 10px;
        margin-bottom: 40px;
      }
      .btn_getintouch {
        color: #ffffff;
        font-family: "Montserrat", sans-serif;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        width: 100%;
        border-style: none;
        border-color: none;
        height: 55px;
        background: #e00d0d;
      }
      
      .main_div_companydetails {
        background: #006bd4;
        width: 100%;
        height: 531px;
        padding: 25px;
      }
      .companydetailicons {
        width: 55px;
        height: 55px;
        margin-top: 20px;
      }
      .heading_company {
          margin-top: 20px;
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        color: #ffffff;
        margin-left: 15px;
      }
      .para_company {
        margin-left: 15px;
        color: #ffffffbd;
        font-family: "Rubik", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 12px;
        text-align: left;
        
      }
      
      

}



@media only screen and (max-width: 1150px) {
    .main_div_GetinTouch {
        background: #f5f5f5;
        width: 100%;
        height: auto;
        padding-top: 50px;
        padding-bottom: 50px;
      }
      .main_div_form_getintouch {
        background: #ffffff;
        width: 100%;
        height: 531px;
        padding: 15px;
      }
      .heading_getintouch {
        color: #e21c1c;
        font-family: "Montserrat", sans-serif;
        font-size: 22px;
        font-weight: 700;
        line-height: 36px;
        text-align: left;
        margin-top: 15px;
        margin-left: 15px;
      }
      .input_style_getintouch {
        width: 100%;
        height: 50px;
        border: 1px solid #2323231f;
        color: #6d6d6d;
        font-family: "Rubik", sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 18.96px;
        text-align: left;
        padding-left: 7px;
        padding-right: 7px;
        outline: none;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .textarea_style_getintouch {
        width: 100%;
        height: 135px;
        border: 1px solid #2323231f;
        color: #6d6d6d;
        font-family: "Rubik", sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 18.96px;
        text-align: left;
        padding-left: 7px;
        padding-right: 7px;
        outline: none;
        margin-top: 10px;
        margin-bottom: 40px;
      }
      .btn_getintouch {
        color: #ffffff;
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        width: 100%;
        border-style: none;
        border-color: none;
        height: 55px;
        background: #e00d0d;
      }
      
      .main_div_companydetails {
        background: #006bd4;
        width: 100%;
        height: 531px;
        padding: 15px;
      }
      .companydetailicons {
        width: 45px;
        height: 45px;
        margin-top: 15px;
      }
      .heading_company {
          margin-top: 15px;
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        color: #ffffff;
        margin-left: 10px;
      }
      .para_company {
        margin-left: 10px;
        color: #ffffffbd;
        font-family: "Rubik", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 12px;
        text-align: left;
        
      }
      
      

}



@media only screen and (max-width: 767px) {
    .main_div_GetinTouch {
        background: #f5f5f5;
        width: 100%;
        height: auto;
        padding-top: 50px;
        padding-bottom: 50px;
      }
      .main_div_form_getintouch {
        background: #ffffff;
        width: 100%;
        height: auto;
        padding: 15px;
      }
      .heading_getintouch {
        color: #e21c1c;
        font-family: "Montserrat", sans-serif;
        font-size: 18px;
        font-weight: 700;
        line-height: 36px;
        text-align: left;
        margin-top: 15px;
        margin-left: 15px;
      }
      .input_style_getintouch {
        width: 100%;
        height: 50px;
        border: 1px solid #2323231f;
        color: #6d6d6d;
        font-family: "Rubik", sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 18.96px;
        text-align: left;
        padding-left: 7px;
        padding-right: 7px;
        outline: none;
        margin-top: 7px;
        margin-bottom: 7px;
      }
      .textarea_style_getintouch {
        width: 100%;
        height: 135px;
        border: 1px solid #2323231f;
        color: #6d6d6d;
        font-family: "Rubik", sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 18.96px;
        text-align: left;
        padding-left: 7px;
        padding-right: 7px;
        outline: none;
        margin-top: 7px;
        margin-bottom: 40px;
      }
      .btn_getintouch {
        color: #ffffff;
        font-family: "Montserrat", sans-serif;
        font-size: 12px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        width: 100%;
        border-style: none;
        border-color: none;
        height: 55px;
        background: #e00d0d;
      }
      
      .main_div_companydetails {
        background: #006bd4;
        width: 100%;
        height: 450px;
        padding: 15px;
      }
      .companydetailicons {
        width: 35px;
        height: 35px;
        margin-top: 15px;
      }
      .heading_company {
          margin-top: 15px;
        font-family: "Montserrat", sans-serif;
        font-size: 12px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        color: #ffffff;
        margin-left: 10px;
      }
      .para_company {
        margin-left: 10px;
        color: #ffffffbd;
        font-family: "Rubik", sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 6px;
        text-align: left;
        
      }
      
      

}