.main_div_inetgration {
  width: 100%;
  height: auto;
  padding-bottom: 25px;
}
.para_integration {
  color: #686868;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  margin-top: 25px;
}
.link_main_div {
  cursor: pointer;
  height: 30px;
  margin-bottom: 10px;
}
.link_main_div:hover {
  box-shadow: 0px 4px 4px 0px #0000000a;
}
.int_link_para {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #686868;
  margin-left: 10px;
}
.symboltickstyle {
  margin-top: -6px;
}

@media only screen and (max-width: 1200px) {
    .main_div_inetgration {
      width: 100%;
      height: auto;
      padding-bottom: 25px;
    }
    .para_integration {
      color: #686868;
      font-family: "Poppins", sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
      text-align: left;
      margin-top: 25px;
    }
    .link_main_div {
      cursor: pointer;
      height: 30px;
      margin-bottom: 10px;
    }
    .link_main_div:hover {
      box-shadow: 0px 4px 4px 0px #0000000a;
    }
    .int_link_para {
      font-family: "Poppins", sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: #686868;
      margin-left: 10px;
    }
    .symboltickstyle {
      margin-top: -6px;
    }
  }
@media only screen and (max-width: 767px) {
  .main_div_inetgration {
    width: 100%;
    height: auto;
    padding-bottom: 25px;
  }
  .para_integration {
    color: #686868;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    margin-top: 25px;
  }
  .link_main_div {
    cursor: pointer;
    height: 30px;
    margin-bottom: 10px;
  }
  .link_main_div:hover {
    box-shadow: 0px 4px 4px 0px #0000000a;
  }
  .int_link_para {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #686868;
    margin-left: 10px;
  }
  .symboltickstyle {
    margin-top: -6px;
  }
}

@media only screen and (max-width: 450px) {
    .main_div_inetgration {
      width: 100%;
      height: auto;
      padding-bottom: 25px;
    }
    .para_integration {
      color: #686868;
      font-family: "Poppins", sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 26px;
      text-align: left;
      margin-top: 25px;
    }
    .link_main_div {
      cursor: pointer;
      height: 30px;
      margin-bottom: 10px;
    }
    .link_main_div:hover {
      box-shadow: 0px 4px 4px 0px #0000000a;
    }
    .int_link_para {
      font-family: "Poppins", sans-serif;
      font-size: 10px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: #686868;
      margin-left: 10px;
    }
    .symboltickstyle {
      margin-top: -6px;
    }
  }
